.profile {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  background: $white;
  border: 1px solid $border-colour;
  border-radius: $default-radius;
  padding: $default-padding;

  @include respond-to('mobile') {
    flex-direction: column;
  }

  &__image {
    width: 20%;
    min-width: 170px;
    overflow: hidden;
    border-radius: $default-radius;
    height: 100%;
    margin-bottom: 1em;

    @include respond-to('mobile') {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }

  &__bio {
    margin-left: 2em;
    flex: 1;
    // width: 100%;

    @include respond-to('mobile') {
      margin: 0;
    }

    h3 {
      margin-top: 0;
    }
  }

  > hr {
    width: 100%;
  }

  .attributes {
    display: flex;
    flex-wrap: wrap;

    &__buttons {
      width: 20%;

      button {
        width: 100%;

        border: 1px solid $green;
        background: transparent;
        border-radius: $default-radius;
      }
    }

    &__info {
      margin-left: 2em;
      flex: 1;
    }
  }
}
