footer {
  background: $black;
  color: $white;

  .container {
    display: flex;
    justify-content: space-between;
    padding: $default-padding;

    @include respond-to('mobile') {
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
    }
  }

  a {
    color: $white;
    font-weight: bold;
    text-decoration: none;
    border-bottom: 1px solid $green;
  }

  nav {
    ul {
      display: flex;
      margin: 0;

      @include respond-to('mobile') {
        display: block;
        padding: 0;
        margin-bottom: 2rem;
        flex-direction: row;
        li + li {
          margin-left: 0;
        }
      }
    }

    li {
      list-style: none;

      + li {
        margin-left: 1em;
      }
    }
    a {
      color: $white;
      text-decoration: none;
      border-bottom: 2px solid transparent;
      transition: 0.3s ease-in-out border-bottom-color;

      &:hover {
        border-color: $green;
      }
    }
  }
}
