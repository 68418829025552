header {
  .header-top-bar {
    position: fixed;
    top: 0;
    left: 0;
    height: 5rem;
    background: white;
    width: 100%;
    padding: $default-padding;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    z-index: 999;
    box-shadow: $elevation-2;

    .logo {
      display: flex;
      text-decoration: none;
      color: $green;

      img {
        width: 30px;
        margin-right: 1em;
      }

      .title {
        font-family: $heading-font;
        font-size: $h5;
        line-height: 1.2;
      }
    }

    .nav-open-hamburger {
      display: none;
      padding: 0;
      border: 0;
      background: none;
      outline: none;

      width: 2em;
      height: 2em;
      position: relative;

      justify-content: center;
      align-items: center;

      cursor: pointer;
      transition: all 0.5s ease-in-out;

      &:focus {
        border: 1px solid $border-colour;
      }

      span,
      span::before,
      span::after {
        width: 100%;
        height: 3px;
        background: $mauve;
        transition: all 0.5s ease-in-out;
        display: block;
      }

      span::before,
      span::after {
        content: '';
        position: absolute;
      }
      span::before {
        transform: translateY(-16px);
      }
      span::after {
        transform: translateY(16px);
      }

      &.open span {
        transform: translateX(-50px);
        background-color: transparent;
        box-shadow: none;

        &::before {
          transform: rotate(45deg) translate(35px, -35px);
        }
        &::after {
          transform: rotate(-45deg) translate(35px, 35px);
        }
      }

      @include respond-to('tablet') {
        display: flex;
      }

      &.open + nav {
        transform: translateX(0);
      }
    }

    nav {
      ul {
        margin: 0;
        display: flex;
        li {
          list-style: none;

          + li {
            margin-left: 1em;
          }

          a {
            color: $text-colour;
            text-decoration: none;
            border-bottom: 2px solid transparent;
            transition: 0.3s ease-in-out border-bottom-color;

            &:hover,
            &.active {
              border-bottom-color: $primary-colour;
            }
          }
        }
      }

      @include respond-to('tablet') {
        position: absolute;
        top: 5em;
        max-width: 300px;
        right: 0;
        background: $eggshell;
        width: 100vw;
        height: calc(100vh - 5em);
        padding: $default-padding;
        box-sizing: border-box;
        box-shadow: $elevation-3;
        transition: all 0.5s ease-in-out;
        transform: translateX(100%);

        ul {
          display: block;
          font-size: $h4;
          li {
            margin-bottom: 0.8em;
            + li {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  .header-content {
    margin-top: 5em;
    padding: $default-padding;
    display: flex;
    align-items: center;
    min-height: calc(70vh - 5rem);
    position: relative;

    box-sizing: border-box;
    color: $white;

    &::before {
      content: '';
      filter: contrast(0.7);
      background-color: $black;
      background-size: cover;
      background-position-x: left;
      background-position-y: top;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
    }

    h1 {
      color: $white;
    }
  }
}

html header .header-content::before {
  background-image: url('../img/hands.jpg');
}

html.webp header .header-content::before {
  background-image: url('../img/hands.webp');
}
