html {
  font-size: 112.5%;
} /*18px*/

p {
  margin-top: 0;
  margin-bottom: 1.15rem;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 2.75rem 0 1.05rem;
  font-family: $heading-font;
  font-weight: 400;
  line-height: 1.15;
}

h1 {
  margin-top: 0;
  font-size: $h1;
}

h2 {
  font-size: $h2;
}

h3 {
  font-size: $h3;
}

h4 {
  font-size: $h4;
}

h5 {
  font-size: $h5;
}

small,
.text_small {
  font-size: $small;
}
