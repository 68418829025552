.card {
  // flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid $border-colour;
  border-radius: $default-radius;
  overflow: hidden;
  background: $white;
  margin-bottom: 1em;

  // + .card {
  //   margin-left: 1em;
  // }

  &__content {
    padding: $default-padding;
    flex: 1;
    display: flex;
    flex-direction: column;

    *:first-child {
      margin-top: 0;
    }

    > *:not(h5):not(.button) {
      flex-grow: 1;
    }
  }

  &__image {
    height: 4em;
    overflow: hidden;
    opacity: 0.8;

    filter: grayscale(80%);
    img {
      height: 4em;
      width: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
}
