.container {
  max-width: $container-width;
  margin: 0 auto;

  @include respond-to('tablet') {
    max-width: $tablet;
  }

  &--half {
    max-width: $container-width / 2;
    padding: $default-padding;
  }
}

// html {
//   height: 100%;
//   overflow: auto;
// }
// body {
//   height: 100%;
// }

body {
  background-color: $white;
  font-family: $text-font;
  font-weight: 400;
  line-height: 1.65;
  color: $text-colour;
}

small {
  font-size: $small;
}

hr {
  border-color: $powder-blue;
}

details[open] {
  margin-bottom: 1rem;
}
summary {
  font-size: $h5;
  cursor: pointer;
  padding: 0.2em 0.5em;
  > * {
    display: inline-block;
    margin: 0;
  }

  &:focus {
    outline-color: $powder-blue;
  }
}

.button {
  background: $green;
  border: 0;
  padding: 1em 3em;
  font-family: $heading-font;
  font-weight: 400;
  display: inline-block;
  text-decoration: none;
  color: $white;
  box-shadow: $elevation-1;
  border-radius: $default-radius;
  cursor: pointer;
  text-align: center;

  &:hover {
    background: lighten($green, 5%);
  }

  &--secondary {
    border: 1px solid $green;
    color: $text-colour;
  }
}

.columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    width: calc(50% - 0.5em);
  }

  @include respond-to('mobile') {
    > div {
      width: 100%;
    }
  }

  &--thirds {
    > div {
      width: calc(33% - 0.5em);

      @include respond-to('tablet') {
        width: calc(50% - 0.5em);
      }

      @include respond-to('mobile') {
        width: 100%;
      }
    }
  }

  // @include respond-to('desktop') {
  //   &--thirds {
  //     > div {
  //       width: calc(33% - 0.5em);
  //     }
  //   }
  // }

  .column {
    flex: 1;
    + .column {
      margin-left: 1em;
    }

    @include respond-to('mobile') {
      + .column {
        margin-left: 0;
      }
    }

    &--wide {
      min-width: 75%;
    }
  }
}

.rows {
  display: flex;
  flex-direction: column;

  .row {
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
}

.side-image img {
  width: 100%;
}

.tint {
  background: lighten($black-coral, 55%);
}
