.side-image-row {
  .column {
    box-sizing: border-box;
    width: 50%;
    min-height: 400px;
    + .column {
      margin-left: 0;
    }
  }
  .column:not(.side-image) {
    display: flex;
    align-items: center;

    &:first-child {
      justify-content: flex-end;
    }
  }

  @include respond-to('mobile') {
    .columns .column {
      width: 100%;
    }

    &.right .columns {
      flex-direction: column-reverse;
    }

    &.left .columns {
      flex-direction: column;
    }
  }

  .side-image {
    background-size: cover;
    position: relative;

    @include respond-to('tablet') {
      max-width: 30%;
    }
    @include respond-to('mobile') {
      max-width: 100%;
    }

    picture {
      position: absolute;
      filter: grayscale(1) brightness(1.2);
      width: 100%;
      height: 100%;
    }
    picture > * {
      object-fit: cover;
      height: 100%;
    }
  }
}
